import { OrderType } from "./ApiTransport"; 
import { PaymentType } from "./ApiTransport"; 
import { OrderInitiator } from "./ApiTransport"; 
import { OrderBalanceType } from "./ApiTransport"; 
import { SubscriptionStatus } from "./ApiTransport"; 
import { VehicleLinkType } from "./ApiTransport"; import { OperatorApi } from "./ApiTransport"; 
import { FacilityInfoApi } from "./ApiTransport"; 
import { UsAddressApi } from "./ApiTransport"; 
import { CoordinatesApi } from "./ApiTransport"; 
import { OrderDebtApi } from "./ApiTransport"; 
import { VehicleApi } from "./ApiTransport"; 
import { RateProgramApi } from "./ApiTransport"; 
import { RateEntryApi } from "./ApiTransport"; 
import { FacilityImageApi } from "./ApiTransport"; 
import { ListedPlanApi } from "./ApiTransport"; 
import { OrderApi } from "./ApiTransport"; 
import { OrderDetailsApi } from "./ApiTransport"; 
import { OrderBalanceApi } from "./ApiTransport"; 
import { InvoiceApi } from "./ApiTransport"; 
import { PaymentApi } from "./ApiTransport"; 
import { ApplyPaymentApi } from "./ApiTransport"; 
import { OrderInfoApi } from "./ApiTransport"; 
import { PaymentChangeApi } from "./ApiTransport"; 
import { SignUpReqApi } from "./ApiTransport"; 
import { LogInReqApi } from "./ApiTransport"; 
import { ChangeEmailReqApi } from "./ApiTransport"; 
import { ChangePasswordReqApi } from "./ApiTransport"; 
import { ForgotPasswordReqApi } from "./ApiTransport"; 
import { ResetPasswordReqApi } from "./ApiTransport"; 
import { ResetCodeCheckReqApi } from "./ApiTransport"; 
import { FacilityApi } from "./ApiTransport"; 
import { ParkerApi } from "./ApiTransport"; 
import { SubscriptionApi } from "./ApiTransport"; 
import { SubscriptionEntryApi } from "./ApiTransport"; 
import { PlanInfoApi } from "./ApiTransport"; 
import { ReservationApi } from "./ApiTransport"; 
import { ReserveInitReqApi } from "./ApiTransport"; 
import { ReserveInitRespApi } from "./ApiTransport"; 
import { ReserveConfirmReqApi } from "./ApiTransport"; 
import { ReserveConfirmRespApi } from "./ApiTransport"; 
import { SubscribeInitReqApi } from "./ApiTransport"; 
import { SubscribeInitRespApi } from "./ApiTransport"; 
import { SubscribeConfirmReqApi } from "./ApiTransport"; 
import { SubscribeConfirmRespApi } from "./ApiTransport"; 
import { SubscribeUpdateReqApi } from "./ApiTransport"; 
import { SubscribeUpdateInitRespApi } from "./ApiTransport"; 
import { SubscribeUpdateDecreaseRespApi } from "./ApiTransport"; 
import { SubscribeUpdateDeferRespApi } from "./ApiTransport"; 
import { SubscribeUpdateConfirmReqApi } from "./ApiTransport"; 
import { SubscribeUpdateConfirmRespApi } from "./ApiTransport"; 
import { OrderPayInitReqApi } from "./ApiTransport"; 
import { OrderPayInitRespApi } from "./ApiTransport"; 
import { SettleInitReqApi } from "./ApiTransport"; 
import { SettleInitRespApi } from "./ApiTransport"; 
import { FacilityParkerBalanceApi } from "./ApiTransport"; 
import { AccessPeriodInfoApi } from "./ApiTransport"; 
import { SubscriptionParkerInviteReqApi } from "./ApiTransport"; 
import { SubscriptionParkerChangeReqApi } from "./ApiTransport"; 
import { SubscriptionParkerRemoveReqApi } from "./ApiTransport"; 
import { VehicleAddReqApi } from "./ApiTransport"; 
import { VehicleDeleteReqApi } from "./ApiTransport"; 
import { VehicleLinkRespApi } from "./ApiTransport"; 
import { SubscriptionParkerApi } from "./ApiTransport"; 
import { ParkerInfoApi } from "./ApiTransport"; 
import { ChangeNameReqApi } from "./ApiTransport"; 
import { ChangePhoneReqApi } from "./ApiTransport"; 
import { SubscribePreviewRespApi } from "./ApiTransport"; 
import { SubscribePreviewReqApi } from "./ApiTransport"; 
import { SubListEntryApi } from "./ApiTransport"; 
import { SubscriptionGuestApi } from "./ApiTransport"; 
import { PlanInfoBasicApi } from "./ApiTransport"; 
import { PaymentMethodRespApi } from "./ApiTransport"; 
import { CardRespApi } from "./ApiTransport"; 
import { BankRespApi } from "./ApiTransport"; import { Operator } from "./ApiTypes"; 
import { FacilityInfo } from "./ApiTypes"; 
import { UsAddress } from "./ApiTypes"; 
import { Coordinates } from "./ApiTypes"; 
import { OrderDebt } from "./ApiTypes"; 
import { Vehicle } from "./ApiTypes"; 
import { RateProgram } from "./ApiTypes"; 
import { RateEntry } from "./ApiTypes"; 
import { FacilityImage } from "./ApiTypes"; 
import { ListedPlan } from "./ApiTypes"; 
import { Order } from "./ApiTypes"; 
import { OrderDetails } from "./ApiTypes"; 
import { OrderBalance } from "./ApiTypes"; 
import { Invoice } from "./ApiTypes"; 
import { Payment } from "./ApiTypes"; 
import { ApplyPayment } from "./ApiTypes"; 
import { OrderInfo } from "./ApiTypes"; 
import { PaymentChange } from "./ApiTypes"; 
import { SignUpReq } from "./ApiTypes"; 
import { LogInReq } from "./ApiTypes"; 
import { ChangeEmailReq } from "./ApiTypes"; 
import { ChangePasswordReq } from "./ApiTypes"; 
import { ForgotPasswordReq } from "./ApiTypes"; 
import { ResetPasswordReq } from "./ApiTypes"; 
import { ResetCodeCheckReq } from "./ApiTypes"; 
import { Facility } from "./ApiTypes"; 
import { Parker } from "./ApiTypes"; 
import { Subscription } from "./ApiTypes"; 
import { SubscriptionEntry } from "./ApiTypes"; 
import { PlanInfo } from "./ApiTypes"; 
import { Reservation } from "./ApiTypes"; 
import { ReserveInitReq } from "./ApiTypes"; 
import { ReserveInitResp } from "./ApiTypes"; 
import { ReserveConfirmReq } from "./ApiTypes"; 
import { ReserveConfirmResp } from "./ApiTypes"; 
import { SubscribeInitReq } from "./ApiTypes"; 
import { SubscribeInitResp } from "./ApiTypes"; 
import { SubscribeConfirmReq } from "./ApiTypes"; 
import { SubscribeConfirmResp } from "./ApiTypes"; 
import { SubscribeUpdateReq } from "./ApiTypes"; 
import { SubscribeUpdateInitResp } from "./ApiTypes"; 
import { SubscribeUpdateDecreaseResp } from "./ApiTypes"; 
import { SubscribeUpdateDeferResp } from "./ApiTypes"; 
import { SubscribeUpdateConfirmReq } from "./ApiTypes"; 
import { SubscribeUpdateConfirmResp } from "./ApiTypes"; 
import { OrderPayInitReq } from "./ApiTypes"; 
import { OrderPayInitResp } from "./ApiTypes"; 
import { SettleInitReq } from "./ApiTypes"; 
import { SettleInitResp } from "./ApiTypes"; 
import { FacilityParkerBalance } from "./ApiTypes"; 
import { AccessPeriodInfo } from "./ApiTypes"; 
import { SubscriptionParkerInviteReq } from "./ApiTypes"; 
import { SubscriptionParkerChangeReq } from "./ApiTypes"; 
import { SubscriptionParkerRemoveReq } from "./ApiTypes"; 
import { VehicleAddReq } from "./ApiTypes"; 
import { VehicleDeleteReq } from "./ApiTypes"; 
import { VehicleLinkResp } from "./ApiTypes"; 
import { SubscriptionParker } from "./ApiTypes"; 
import { ParkerInfo } from "./ApiTypes"; 
import { ChangeNameReq } from "./ApiTypes"; 
import { ChangePhoneReq } from "./ApiTypes"; 
import { SubscribePreviewResp } from "./ApiTypes"; 
import { SubscribePreviewReq } from "./ApiTypes"; 
import { SubListEntry } from "./ApiTypes"; 
import { SubscriptionGuest } from "./ApiTypes"; 
import { PlanInfoBasic } from "./ApiTypes"; 
import { PaymentMethodResp } from "./ApiTypes"; 
import { CardResp } from "./ApiTypes"; 
import { BankResp } from "./ApiTypes"; 
import { toCents } from "./Money";
import { emitDuration, emitLocalDateTime, emitLocalTime, emitLocalDate, emitInstant } from "./ApiConverters";
import { Duration } from "js-joda";
export function emitOperator(o: Operator): OperatorApi {
    return {
        operatorId: o.operatorId,
        name: o.name,
        address: emitUsAddress( o.address ),
        emailAddress: o.emailAddress,
        phoneNumber: o.phoneNumber,
        website: o.website,
        topTitle: o.topTitle,
        topText: o.topText,
        bottomLeftTitle: o.bottomLeftTitle,
        bottomLeftText: o.bottomLeftText,
        bottomRightTitle: o.bottomRightTitle,
        bottomRightText: o.bottomRightText,
    }
}

export function emitFacilityInfo(f: FacilityInfo): FacilityInfoApi {
    return {
        facilityId: f.facilityId,
        operatorId: f.operatorId,
        name: f.name,
        description: f.description,
        timeZone: f.timeZone,
        address: emitUsAddress( f.address ),
        coordinates: emitCoordinates( f.coordinates ),
        listImageId: f.listImageId != null ? f.listImageId : null,
        entryInstructions: f.entryInstructions,
        phoneNumber: f.phoneNumber,
        emailAddress: f.emailAddress,
        clearanceInches: f.clearanceInches,
    }
}

export function emitUsAddress(u: UsAddress): UsAddressApi {
    return {
        addressLine1: u.addressLine1,
        addressLine2: u.addressLine2,
        city: u.city,
        state: u.state,
        zipCode: u.zipCode,
    }
}

export function emitCoordinates(c: Coordinates): CoordinatesApi {
    return {
        lat: c.lat,
        lng: c.lng,
    }
}

export function emitOrderDebt(o: OrderDebt): OrderDebtApi {
    return {
        orderId: o.orderId,
        createdWhen: emitInstant( o.createdWhen ),
        orderTotal: toCents( o.orderTotal ),
        paid: toCents( o.paid ),
        unpaid: toCents( o.unpaid ),
    }
}

export function emitVehicle(v: Vehicle): VehicleApi {
    return {
        vehicleId: v.vehicleId,
        make: v.make,
        model: v.model,
        color: v.color,
        stateCode: v.stateCode,
        licensePlateNumber: v.licensePlateNumber,
    }
}

export function emitRateProgram(r: RateProgram): RateProgramApi {
    return {
        rateProgramId: r.rateProgramId,
        facilityId: r.facilityId,
        name: r.name,
        start: emitLocalDateTime( r.start ),
        end: r.end != null ? emitLocalDateTime( r.end ) : null,
        inAfter: r.inAfter != null ? emitLocalTime( r.inAfter ) : null,
        inBefore: r.inBefore != null ? emitLocalTime( r.inBefore ) : null,
        outAfter: r.outAfter != null ? emitLocalTime( r.outAfter ) : null,
        outBefore: r.outBefore != null ? emitLocalTime( r.outBefore ) : null,
        entries: r.entries.map( el => emitRateEntry( el ) ),
    }
}

export function emitRateEntry(r: RateEntry): RateEntryApi {
    return {
        rateEntryId: r.rateEntryId,
        rateProgramId: r.rateProgramId,
        upTo: emitDuration( r.upTo ),
        rate: toCents( r.rate ),
    }
}

export function emitFacilityImage(f: FacilityImage): FacilityImageApi {
    return {
        facilityImageId: f.facilityImageId,
        storedImageId: f.storedImageId,
        orderId: f.orderId,
        caption: f.caption,
        altText: f.altText,
    }
}

export function emitListedPlan(l: ListedPlan): ListedPlanApi {
    return {
        planId: l.planId,
        planEntryId: l.planEntryId,
        name: l.name,
        description: l.description,
        accessHours: l.accessHours,
        dayOfMonthInvoice: l.dayOfMonthInvoice,
        dayOfMonthDue: l.dayOfMonthDue,
        dayOfMonthLate: l.dayOfMonthLate,
        price: toCents( l.price ),
    }
}

export function emitOrder(o: Order): OrderApi {
    return {
        orderId: o.orderId,
        initiator: o.initiator,
        initiatedByStaffId: o.initiatedByStaffId != null ? o.initiatedByStaffId : null,
        parkerId: o.parkerId,
        facilityId: o.facilityId,
        price: toCents( o.price ),
        createdWhen: emitInstant( o.createdWhen ),
        processorPaymentIntentKey: o.processorPaymentIntentKey != null ? o.processorPaymentIntentKey : null,
        type: o.type,
        facility: emitFacilityInfo( o.facility ),
        submittedWhen: o.submittedWhen != null ? emitInstant( o.submittedWhen ) : null,
        balance: emitOrderBalance( o.balance ),
        summary: o.summary,
    }
}

export function emitOrderDetails(o: OrderDetails): OrderDetailsApi {
    return {
        order: emitOrder( o.order ),
        balanceChanges: o.balanceChanges.map( el => emitOrderBalance( el ) ),
        summary: o.summary,
    }
}

export function emitOrderBalance(o: OrderBalance): OrderBalanceApi {
    return {
        orderBalanceId: o.orderBalanceId,
        type: o.type,
        orderId: o.orderId,
        createdWhen: emitInstant( o.createdWhen ),
        debt: toCents( o.debt ),
        paid: toCents( o.paid ),
        balance: toCents( o.balance ),
        totalDebt: toCents( o.totalDebt ),
        totalPaid: toCents( o.totalPaid ),
        reason: o.reason != null ? o.reason : null,
        paymentId: o.paymentId != null ? o.paymentId : null,
    }
}

export function emitInvoice(i: Invoice): InvoiceApi {
    return {
        invoiceId: i.invoiceId,
        invoiceNumber: i.invoiceNumber,
        orderId: i.orderId,
        createdWhen: emitInstant( i.createdWhen ),
        dueWhen: emitLocalDate( i.dueWhen ),
        openingBalance: toCents( i.openingBalance ),
        amountDue: toCents( i.amountDue ),
        endingBalance: toCents( i.endingBalance ),
        emailSentWhen: i.emailSentWhen != null ? emitInstant( i.emailSentWhen ) : null,
        facility: emitFacilityInfo( i.facility ),
        order: emitOrderInfo( i.order ),
        parker: emitParkerInfo( i.parker ),
    }
}

export function emitPayment(p: Payment): PaymentApi {
    return {
        paymentId: p.paymentId,
        parentPaymentId: p.parentPaymentId != null ? p.parentPaymentId : null,
        facilityId: p.facilityId,
        facility: emitFacilityInfo( p.facility ),
        parkerId: p.parkerId,
        type: p.type,
        amount: toCents( p.amount ),
        balance: toCents( p.balance ),
        remaining: toCents( p.remaining ),
        paymentMethod: p.paymentMethod,
        changes: p.changes.map( el => emitPaymentChange( el ) ),
        applications: p.applications.map( el => emitApplyPayment( el ) ),
        createdWhen: emitInstant( p.createdWhen ),
    }
}

export function emitApplyPayment(a: ApplyPayment): ApplyPaymentApi {
    return {
        orderBalanceId: a.orderBalanceId,
        type: a.type,
        facilityId: a.facilityId,
        parkerId: a.parkerId,
        orderId: a.orderId,
        debt: toCents( a.debt ),
        paid: toCents( a.paid ),
        balance: toCents( a.balance ),
        totalDebt: toCents( a.totalDebt ),
        totalPaid: toCents( a.totalPaid ),
    }
}

export function emitOrderInfo(o: OrderInfo): OrderInfoApi {
    return {
        orderId: o.orderId,
        initiator: o.initiator,
        initiatedByStaffId: o.initiatedByStaffId != null ? o.initiatedByStaffId : null,
        parkerId: o.parkerId,
        facilityId: o.facilityId,
        price: toCents( o.price ),
        createdWhen: emitInstant( o.createdWhen ),
        processorPaymentIntentKey: o.processorPaymentIntentKey != null ? o.processorPaymentIntentKey : null,
        orderType: o.orderType,
        submittedWhen: o.submittedWhen != null ? emitInstant( o.submittedWhen ) : null,
        balance: emitOrderBalance( o.balance ),
        summary: o.summary,
    }
}

export function emitPaymentChange(p: PaymentChange): PaymentChangeApi {
    return {
        paymentId: p.paymentId,
        parentPaymentId: p.parentPaymentId != null ? p.parentPaymentId : null,
        facilityId: p.facilityId,
        parkerId: p.parkerId,
        type: p.type,
        amount: toCents( p.amount ),
        balance: toCents( p.balance ),
        createdWhen: emitInstant( p.createdWhen ),
    }
}

export function emitSignUpReq(s: SignUpReq): SignUpReqApi {
    return {
        name: s.name,
        emailAddress: s.emailAddress,
        phoneNumber: s.phoneNumber,
        password: s.password,
        passwordConfirm: s.passwordConfirm,
    }
}

export function emitLogInReq(l: LogInReq): LogInReqApi {
    return {
        emailAddress: l.emailAddress,
        password: l.password,
    }
}

export function emitChangeEmailReq(c: ChangeEmailReq): ChangeEmailReqApi {
    return {
        currentPassword: c.currentPassword,
        newEmailAddress: c.newEmailAddress,
    }
}

export function emitChangePasswordReq(c: ChangePasswordReq): ChangePasswordReqApi {
    return {
        currentPassword: c.currentPassword,
        newPassword: c.newPassword,
    }
}

export function emitForgotPasswordReq(f: ForgotPasswordReq): ForgotPasswordReqApi {
    return {
        emailAddress: f.emailAddress,
    }
}

export function emitResetPasswordReq(r: ResetPasswordReq): ResetPasswordReqApi {
    return {
        resetPasswordCode: r.resetPasswordCode,
        newPassword: r.newPassword,
    }
}

export function emitResetCodeCheckReq(r: ResetCodeCheckReq): ResetCodeCheckReqApi {
    return {
        resetPasswordCode: r.resetPasswordCode,
    }
}

export function emitFacility(f: Facility): FacilityApi {
    return {
        facilityId: f.facilityId,
        name: f.name,
        description: f.description,
        timeZone: f.timeZone,
        address: emitUsAddress( f.address ),
        coordinates: emitCoordinates( f.coordinates ),
        listImageId: f.listImageId != null ? f.listImageId : null,
        entryInstructions: f.entryInstructions,
        physicalCapacity: f.physicalCapacity,
        enforcedCapacity: f.enforcedCapacity,
        clearanceInches: f.clearanceInches,
        website: f.website,
        emailAddress: f.emailAddress,
        phoneNumber: f.phoneNumber,
        images: f.images.map( el => emitFacilityImage( el ) ),
        hourlyRates: f.hourlyRates.map( el => emitRateProgram( el ) ),
        listedPlans: f.listedPlans.map( el => emitListedPlan( el ) ),
    }
}

export function emitParker(p: Parker): ParkerApi {
    return {
        parkerId: p.parkerId,
        operatorId: p.operatorId,
        name: p.name,
        emailAddress: p.emailAddress,
        phoneNumber: p.phoneNumber,
        createdWhen: emitInstant( p.createdWhen ),
    }
}

export function emitSubscription(s: Subscription): SubscriptionApi {
    return {
        subscriptionId: s.subscriptionId,
        parkerId: s.parkerId,
        status: s.status,
        start: emitLocalDate( s.start ),
        currentEntry: emitSubscriptionEntry( s.currentEntry ),
        history: s.history.map( el => emitSubscriptionEntry( el ) ),
        facility: emitFacilityInfo( s.facility ),
        accessPeriods: s.accessPeriods.map( el => emitAccessPeriodInfo( el ) ),
        parkers: s.parkers.map( el => emitSubscriptionParker( el ) ),
        vehicles: s.vehicles.map( el => emitVehicle( el ) ),
        paymentMethod: s.paymentMethod != null ? s.paymentMethod : null,
    }
}

export function emitSubscriptionEntry(s: SubscriptionEntry): SubscriptionEntryApi {
    return {
        subscriptionEntryId: s.subscriptionEntryId,
        subscriptionId: s.subscriptionId,
        planEntryId: s.planEntryId,
        planPriceId: s.planPriceId,
        quantity: s.quantity,
        start: emitLocalDate( s.start ),
        end: s.end != null ? emitLocalDate( s.end ) : null,
        isAccountsReceivable: s.isAccountsReceivable,
        accessPeriods: s.accessPeriods.map( el => emitAccessPeriodInfo( el ) ),
        price: toCents( s.price ),
        plan: emitPlanInfo( s.plan ),
    }
}

export function emitPlanInfo(p: PlanInfo): PlanInfoApi {
    return {
        planId: p.planId,
        facilityId: p.facilityId,
        name: p.name,
        description: p.description,
        accessHours: p.accessHours,
        dayOfMonthInvoice: p.dayOfMonthInvoice,
        dayOfMonthDue: p.dayOfMonthDue,
        dayOfMonthLate: p.dayOfMonthLate,
    }
}

export function emitReservation(r: Reservation): ReservationApi {
    return {
        reservationId: r.reservationId,
        facilityId: r.facilityId,
        parkerId: r.parkerId,
        start: emitLocalDateTime( r.start ),
        end: emitLocalDateTime( r.end ),
        actualStart: emitInstant( r.actualStart ),
        actualEnd: emitInstant( r.actualEnd ),
        duration: emitDuration( r.duration ),
        rateProgramId: r.rateProgramId,
        price: toCents( r.price ),
        createdWhen: emitInstant( r.createdWhen ),
        facility: emitFacilityInfo( r.facility ),
        rateProgram: emitRateProgram( r.rateProgram ),
    }
}

export function emitReserveInitReq(r: ReserveInitReq): ReserveInitReqApi {
    return {
        facilityId: r.facilityId,
        start: emitLocalDateTime( r.start ),
        end: emitLocalDateTime( r.end ),
    }
}

export function emitReserveInitResp(r: ReserveInitResp): ReserveInitRespApi {
    return {
        facilityId: r.facilityId,
        start: emitLocalDateTime( r.start ),
        end: emitLocalDateTime( r.end ),
        stayDuration: emitDuration( r.stayDuration ),
        chargeDuration: emitDuration( r.chargeDuration ),
        orderId: r.orderId,
        price: toCents( r.price ),
        customerSessionClientSecret: r.customerSessionClientSecret,
        paymentIntentClientSecret: r.paymentIntentClientSecret,
    }
}

export function emitReserveConfirmReq(r: ReserveConfirmReq): ReserveConfirmReqApi {
    return {
        orderId: r.orderId,
    }
}

export function emitReserveConfirmResp(r: ReserveConfirmResp): ReserveConfirmRespApi {
    return {
        statusMsg: r.statusMsg,
    }
}

export function emitSubscribeInitReq(s: SubscribeInitReq): SubscribeInitReqApi {
    return {
        facilityId: s.facilityId,
        planEntryId: s.planEntryId,
        start: emitLocalDate( s.start ),
        quantity: s.quantity,
    }
}

export function emitSubscribeInitResp(s: SubscribeInitResp): SubscribeInitRespApi {
    return {
        facilityId: s.facilityId,
        planEntryId: s.planEntryId,
        start: emitLocalDate( s.start ),
        quantity: s.quantity,
        price: toCents( s.price ),
        orderId: s.orderId,
        paymentIntentClientSecret: s.paymentIntentClientSecret,
        customerSessionClientSecret: s.customerSessionClientSecret,
    }
}

export function emitSubscribeConfirmReq(s: SubscribeConfirmReq): SubscribeConfirmReqApi {
    return {
        orderId: s.orderId,
    }
}

export function emitSubscribeConfirmResp(s: SubscribeConfirmResp): SubscribeConfirmRespApi {
    return {
        status: s.status,
    }
}

export function emitSubscribeUpdateReq(s: SubscribeUpdateReq): SubscribeUpdateReqApi {
    return {
        facilityId: s.facilityId,
        subscriptionId: s.subscriptionId,
        newPlanEntryId: s.newPlanEntryId,
        newQuantity: s.newQuantity,
    }
}

export function emitSubscribeUpdateInitResp(s: SubscribeUpdateInitResp): SubscribeUpdateInitRespApi {
    return {
        facilityId: s.facilityId,
        planEntryId: s.planEntryId,
        start: emitLocalDate( s.start ),
        quantity: s.quantity,
        price: toCents( s.price ),
        orderId: s.orderId != null ? s.orderId : null,
        paymentIntentClientSecret: s.paymentIntentClientSecret != null ? s.paymentIntentClientSecret : null,
        customerSessionClientSecret: s.customerSessionClientSecret != null ? s.customerSessionClientSecret : null,
    }
}

export function emitSubscribeUpdateDecreaseResp(s: SubscribeUpdateDecreaseResp): SubscribeUpdateDecreaseRespApi {
    return {
        subscriptionEntryId: s.subscriptionEntryId,
    }
}

export function emitSubscribeUpdateDeferResp(s: SubscribeUpdateDeferResp): SubscribeUpdateDeferRespApi {
    return {
        subscriptionEntryId: s.subscriptionEntryId,
        orderId: s.orderId,
    }
}

export function emitSubscribeUpdateConfirmReq(s: SubscribeUpdateConfirmReq): SubscribeUpdateConfirmReqApi {
    return {
        orderId: s.orderId,
    }
}

export function emitSubscribeUpdateConfirmResp(s: SubscribeUpdateConfirmResp): SubscribeUpdateConfirmRespApi {
    return {
        status: s.status,
    }
}

export function emitOrderPayInitReq(o: OrderPayInitReq): OrderPayInitReqApi {
    return {
        orderId: o.orderId,
    }
}

export function emitOrderPayInitResp(o: OrderPayInitResp): OrderPayInitRespApi {
    return {
        orderId: o.orderId,
        price: toCents( o.price ),
        paymentIntentClientSecret: o.paymentIntentClientSecret,
        customerSessionClientSecret: o.customerSessionClientSecret,
    }
}

export function emitSettleInitReq(s: SettleInitReq): SettleInitReqApi {
    return {
        facilityId: s.facilityId,
    }
}

export function emitSettleInitResp(s: SettleInitResp): SettleInitRespApi {
    return {
        facility: emitFacilityInfo( s.facility ),
        orders: s.orders.map( el => emitOrder( el ) ),
        price: toCents( s.price ),
        paymentIntentClientSecret: s.paymentIntentClientSecret,
        customerSessionClientSecret: s.customerSessionClientSecret,
    }
}

export function emitFacilityParkerBalance(f: FacilityParkerBalance): FacilityParkerBalanceApi {
    return {
        facility: emitFacilityInfo( f.facility ),
        balance: toCents( f.balance ),
        orderDebts: f.orderDebts.map( el => emitOrderDebt( el ) ),
    }
}

export function emitAccessPeriodInfo(a: AccessPeriodInfo): AccessPeriodInfoApi {
    return {
        accessPeriodId: a.accessPeriodId,
        subscriptionEntryId: a.subscriptionEntryId,
        quantity: a.quantity,
        start: emitLocalDate( a.start ),
        end: emitLocalDate( a.end ),
        isDelivered: a.isDelivered,
        price: toCents( a.price ),
    }
}

export function emitSubscriptionParkerInviteReq(s: SubscriptionParkerInviteReq): SubscriptionParkerInviteReqApi {
    return {
        subscriptionId: s.subscriptionId,
        quantity: s.quantity,
        name: s.name,
        emailAddress: s.emailAddress,
    }
}

export function emitSubscriptionParkerChangeReq(s: SubscriptionParkerChangeReq): SubscriptionParkerChangeReqApi {
    return {
        parkerId: s.parkerId,
        quantity: s.quantity,
    }
}

export function emitSubscriptionParkerRemoveReq(s: SubscriptionParkerRemoveReq): SubscriptionParkerRemoveReqApi {
    return {
        parkerId: s.parkerId,
    }
}

export function emitVehicleAddReq(v: VehicleAddReq): VehicleAddReqApi {
    return {
        vehicleId: v.vehicleId,
    }
}

export function emitVehicleDeleteReq(v: VehicleDeleteReq): VehicleDeleteReqApi {
    return {
        vehicleId: v.vehicleId,
    }
}

export function emitVehicleLinkResp(v: VehicleLinkResp): VehicleLinkRespApi {
    return {
        vehicleLinkId: v.vehicleLinkId,
        vehicleId: v.vehicleId,
        type: v.type,
        subscriptionId: v.subscriptionId,
    }
}

export function emitSubscriptionParker(s: SubscriptionParker): SubscriptionParkerApi {
    return {
        parker: emitParkerInfo( s.parker ),
        quantity: s.quantity,
        vehicles: s.vehicles.map( el => emitVehicle( el ) ),
    }
}

export function emitParkerInfo(p: ParkerInfo): ParkerInfoApi {
    return {
        parkerId: p.parkerId,
        operatorId: p.operatorId,
        name: p.name,
        emailAddress: p.emailAddress,
        phoneNumber: p.phoneNumber != null ? p.phoneNumber : null,
        createdWhen: emitInstant( p.createdWhen ),
    }
}

export function emitChangeNameReq(c: ChangeNameReq): ChangeNameReqApi {
    return {
        name: c.name,
    }
}

export function emitChangePhoneReq(c: ChangePhoneReq): ChangePhoneReqApi {
    return {
        phoneNumber: c.phoneNumber,
    }
}

export function emitSubscribePreviewResp(s: SubscribePreviewResp): SubscribePreviewRespApi {
    return {
        planEntryId: s.planEntryId,
        start: emitLocalDate( s.start ),
        quantity: s.quantity,
        price: toCents( s.price ),
    }
}

export function emitSubscribePreviewReq(s: SubscribePreviewReq): SubscribePreviewReqApi {
    return {
        planEntryId: s.planEntryId,
        start: emitLocalDate( s.start ),
        quantity: s.quantity,
    }
}

export function emitSubListEntry(s: SubListEntry): SubListEntryApi {
    return {
        subscriptionId: s.subscriptionId,
        parkerId: s.parkerId,
        status: s.status,
        quantity: s.quantity,
        unitPrice: s.unitPrice != null ? s.unitPrice : null,
        price: s.price != null ? s.price : null,
        plan: emitPlanInfoBasic( s.plan ),
        facility: emitFacilityInfo( s.facility ),
    }
}

export function emitSubscriptionGuest(s: SubscriptionGuest): SubscriptionGuestApi {
    return {
        subscriptionId: s.subscriptionId,
        parkerId: s.parkerId,
        status: s.status,
        start: emitLocalDate( s.start ),
        quantity: s.quantity,
        parker: emitParkerInfo( s.parker ),
        plan: emitPlanInfoBasic( s.plan ),
        facility: emitFacilityInfo( s.facility ),
        vehicles: s.vehicles.map( el => emitVehicle( el ) ),
        accessPeriods: s.accessPeriods.map( el => emitAccessPeriodInfo( el ) ),
    }
}

export function emitPlanInfoBasic(p: PlanInfoBasic): PlanInfoBasicApi {
    return {
        planId: p.planId,
        planEntryId: p.planEntryId,
        name: p.name,
        accessHours: p.accessHours,
    }
}

export function emitPaymentMethodResp(p: PaymentMethodResp): PaymentMethodRespApi {
    return {
        paymentMethodId: p.paymentMethodId,
        type: p.type,
        card: p.card != null ? emitCardResp( p.card ) : null,
        usBankAccount: p.usBankAccount != null ? emitBankResp( p.usBankAccount ) : null,
        subscriptions: p.subscriptions.map( el => emitSubListEntry( el ) ),
    }
}

export function emitCardResp(c: CardResp): CardRespApi {
    return {
        brand: c.brand,
        last4Digits: c.last4Digits,
        expirationMonth: c.expirationMonth,
        expirationYear: c.expirationYear,
    }
}

export function emitBankResp(b: BankResp): BankRespApi {
    return {
        bank: b.bank,
        last4Digits: b.last4Digits,
    }
}


