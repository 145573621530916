//third-party
import { multiply    } from "dinero.js";
import { LocalDate  } from "js-joda";
import { Card } from "react-bootstrap";

//spotsync
import { Money, showMoney } from "../Data/Money";
import { showDate } from "../Data/Date";
import { ListedPlan } from "../Data/ApiTypes";
import { AddSubtract } from "./AddSubtract";

interface MonthlyParkingDetailsProps {
    color?:           string;
    start:            LocalDate;
    selOpt:           ListedPlan;
    disabled?:        boolean;
    originalQuantity: number;
    quantity:         number;
    proratedFee:      Money;
    setQuantity?:     ( qty: number ) => void;
}

// function prorate( listedPlan: ListedPlan, start: LocalDate, quantity: number | undefined ) {
//     const dayOfMonDue  = listedPlan.dayOfMonthDue;
//     const nextMon      = YearMonth.from( start ).plusMonths( 1 ).atDay( dayOfMonDue );
//     const daysTill     = nextMon.toEpochDay() - start.toEpochDay();
//     const daysInCurMon = 30; //YearMonth.from( start ).lengthOfMonth();
//     const rate         = multiply( listedPlan.price, quantity ?? 1 );
//     const [proratedFee, _] = allocate( rate, [daysTill, daysInCurMon - daysTill ] );
//     return { rate, proratedFee };
// }

// function prorate(listedPlan: ListedPlan, start: LocalDate, quantity: number | undefined) {
//     const dayOfMonDue = listedPlan.dayOfMonthDue;
//     const currentMonth = YearMonth.from(start);
//     const prevMon = currentMonth.atDay(dayOfMonDue);
//     const nextMon = currentMonth.plusMonths(1).atDay(dayOfMonDue);

//     // Convert to epoch days for exact matching with C#
//     const denom = nextMon.toEpochDay() - prevMon.toEpochDay();
//     const days = Math.min(denom, nextMon.toEpochDay() - start.toEpochDay());

//     const rate = multiply(listedPlan.price, quantity ?? 1);

//     if (denom - days === 0) {
//         return { rate, proratedFee: rate };
//     }

//     // Ensure exact ratio matching by using epoch days
//     const [proratedFee] = allocate(rate, [days, denom - days]);
//     return { rate, proratedFee };
// }

export function MonthlyParkingModify( props: MonthlyParkingDetailsProps ) {
    const { color = "primary",
            start,
            selOpt,
            originalQuantity: prevQty,
            quantity:         qty,
            proratedFee,
            setQuantity,
            disabled = false } = { ...props };

    const spotsAdded = Math.max( 0, qty - prevQty )
    const rate       = multiply( selOpt.price, qty ?? 1 );
    return <Card className={`border-2 border-${color} my-1`}>
        <Card.Header className={`text-center bg-${color}-subtle`}>
            Update Plan Parking
        </Card.Header>
        <Card.Body>
            <div className="mt-2 w-auto gap-3" style={{ display: "grid", gridTemplateColumns: "auto max-content" }}>
                <div>
                    Plan Name
                </div>
                <div className="text-secondary">
                    {selOpt.description}
                </div>
                <div className="text-start d-flex align-items-center">
                    Current # Spots
                </div>
                <div className="justify-content-end d-inline-flex align-items-center justify-content-start justify-content-md-center gap-1">
                    <AddSubtract variant="secondary" quantity={prevQty} set={ () => 0 } />
                </div>

                {( qty !== undefined && setQuantity !== undefined ) && <>
                    <div className="text-start d-flex align-items-center">
                        New # Spots
                    </div>
                    <div className="justify-content-end d-inline-flex align-items-center justify-content-start justify-content-md-center gap-1">
                        <AddSubtract quantity={qty} set={ qty => disabled !== true && setQuantity( qty ) } />
                    </div>
                </>}

                <div className="text-start">
                    Spots Added
                </div>
                <div className="text-end">
                    {spotsAdded}
                </div>

                <div className="text-start">Start Date</div>
                <div className="text-end">{showDate( start )}</div>

                <div className="text-start">What You'll Pay Today</div>
                <div className="text-end fw-bold">{proratedFee !== undefined && showMoney( proratedFee )}</div>

                <div className="text-start">New Monthly Payment</div>
                <div className="text-end fw-bold">{showMoney( rate )}</div>
            </div>
        </Card.Body>
    </Card>;
}