import { Card } from "react-bootstrap";
import { ReactNode } from "react";
import React from "react";
import { SquareButton } from "./SquareButton";

export interface CardSectionProps {
    icon:     ReactNode;
    title:    ReactNode;
    content:  ReactNode;
    change:   ReactNode;
    onClick?: () => void;
}

export function CardSection( props: CardSectionProps ) {
    const {
        icon,
        title,
        content,
        change,
        onClick = () => 0 } = { ...props };
    return <React.Fragment>
        <Card>
            <Card.Body className="text-start 100 d-flex align-items-center gap-3">
                <div>
                    <div className="text-muted">
                        <small>{title}</small>
                    </div>
                    <div>
                        {content}
                    </div>
                </div>
            </Card.Body>
        </Card>
        <SquareButton onClick={onClick}
                className="status-btn text-black d-flex flex-column text-center align-items-center justify-content-center ms-auto w-100 h-100 ratio-1x1">
            <div className="fs-5">
                {icon}
            </div>
            <div>
                {change}
            </div>
        </SquareButton>
    </React.Fragment>
}
