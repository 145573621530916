import { Alert, Card } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Link, useNavigate    } from "react-router-dom";
import { Button  } from "./Controls/Button";
import { useState } from "react";
import { Alphabet, Asterisk, At, Telephone } from "react-bootstrap-icons";
import { CenteredPage } from "./Controls/CenteredPage";
import { Input } from "./Controls/Input";
import { HeaderText } from "./Controls/HeaderText";
import { Api } from "./Data/Api";
import { useAtom } from "jotai";
import { authAtom, redirectAtom } from "./Data/Atoms";
import { parseParker } from "./Data/ApiParse";

export const SignUpPage = () => {
    const [auth,   setAuth] = useAtom( authAtom );
    const nav = useNavigate();
    const [errMsg, setErrMsg] = useState<string>();
    const [redirect, setRedirect] = useAtom( redirectAtom );

    interface SignUpForm {
        name:            string;
        emailAddress:    string;
        phoneNumber:     string;
        password:        string;
        passwordConfirm: string;
    }

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm<SignUpForm>();

    const password = watch( "password" );
    function onSubmit( data: SignUpForm ) {
        Api.signUp( { ...data } )
            .then( res => {
                if( res.isOk ) {
                    const parker = parseParker( res.value );
                    setAuth( { isLoggedIn: true, account: parker } );
                    if( redirect !== undefined ) {
                        setRedirect( undefined );
                        nav( `${redirect}` );
                    }
                    return nav( "/" );
                }
                setErrMsg( res.error );
            } );
    }

    return <CenteredPage sm="10" md="8" lg="6" xl="4" style={{ maxWidth: "30em" }}>
        <Card className="w-100">
            <form onSubmit={handleSubmit( onSubmit )}>
                <Card.Header>
                    <HeaderText>Sign Up</HeaderText>
                </Card.Header>
                <Card.Body>
                    <Input
                        type="text"
                        label="Name"
                        icon={<Alphabet className="fs-5" />}
                        placeholder="Enter your full name"
                        error={errors.emailAddress}
                        {...register( "name", { required: "Name is required" } )}
                    />
                    
                    <Input
                        type="email"
                        label="Email Address"
                        icon={<At className="fs-5" />}
                        placeholder="Enter your email address"
                        error={errors.emailAddress}
                        {...register( "emailAddress", { required: "Email Address is required" } )}
                    />

                    <Input
                        type="tel"
                        label="Phone Number"
                        icon={<Telephone className="fs-5" />}
                        placeholder="###-###-####"
                        error={errors.phoneNumber}
                        {...register("phoneNumber", {
                            required: "Phone number is required",
                            pattern: {
                                value: /^\d{3}-\d{3}-\d{4}$/,
                                message: "Phone number must be in format ###-###-####"
                            }
                        })}
                    />
                    <Input
                        type="password"
                        label="Password"
                        icon={<Asterisk />}
                        error={errors.password}
                        {...register( "password", { required: "Password is required" } )}
                    />
                    <Input
                        type="password"
                        label="Confirm Password"
                        icon={<Asterisk />}
                        error={errors.passwordConfirm}
                        {...register( "passwordConfirm", {
                            required: "Confirm Password is required",
                            validate: ( value ) =>
                                value === password || "Passwords do not match",
                        } )}
                    />
                    {errMsg && <Alert variant="danger">{errMsg}</Alert>}
                    <div>
                        <Link to="/login">Already have an account? Log In</Link>
                    </div>
                </Card.Body>
                <Card.Footer>
                    <div className="d-flex flex-row justify-content-end">
                        <Button type="submit">Register</Button>
                    </div>
                </Card.Footer>
            </form>
        </Card>
    </CenteredPage>;
};
