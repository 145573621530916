import { Modal      } from "react-bootstrap";
import { Button     } from "./Controls/Button";
import { HeaderText } from "./Controls/HeaderText";
import { PaymentMethodResp } from "./Data/ApiTypes";

interface DeletePaymentMethodModalProps {
    method:   PaymentMethodResp;
    show:     boolean;
    setShow:  ( show: boolean ) => void;
    onDelete: ( pm: PaymentMethodResp ) => void;
    used:     boolean;
}

export function DeletePaymentMethodModal( { method, show, setShow, used, onDelete }: DeletePaymentMethodModalProps ) {
    return <Modal centered show={show} onEscapeKeyDown={ () => setShow( false ) }>
        <Modal.Header>
            <HeaderText>Delete Payment Method</HeaderText>
        </Modal.Header>
        <Modal.Body>
            {used && <div>
                <div className="alert alert-danger text-danger fw-bold">
                    If you continue, you will leave some plans without a payment method
                </div>
                <div className="text-danger-light mb-4">
                    Make sure you update your plans to use new payment methods if you want them to renew.
                </div>
            </div>}
            {used === false && <div className="mb-4">
                This payment method is not in use and may be safely deleted.
            </div>}
            <div className="w-100 d-flex flex-row justify-content-end gap-2">
                <Button className="w-50" onClick={() => setShow( false )}>
                    Cancel
                </Button>
                <Button className="w-50 bg-danger text-white border-danger"
                        onClick={ () => {
                            setShow( false );
                            onDelete( method );
                        } }>
                    Delete
                </Button>
            </div>
        </Modal.Body>
    </Modal>;
}