import { CardResp, PaymentMethodResp } from "../Data/ApiTypes";

export interface PaymentMethodCardProps {
    card:       CardResp;
    className?: string;
    used?:      boolean;
}

function getCardLogo( cardBrand: string ) {
    switch( cardBrand ) {
        case "visa":       return "/img/card-logo-visa.svg";
        case "mastercard": return "/img/card-logo-mastercard.svg";
        case "amex":       return "/img/card-logo-american-express.svg";
        case "discover":   return "/img/card-logo-discover.png";
        default:
            return null;
    }
}

export function PaymentMethodCard( props: PaymentMethodCardProps ) {
    const { card,
            className = "",
            used = false
          } = props;
    return <div className={`${className} shadow credit-card border border-2 border-primary rounded-4 p-3 position-relative text-black`}>
        <div className="grid-area-a align-self-center text-center" style={{ textTransform: "capitalize" }}>
            {getCardLogo( card.brand ) !== null ? <img height={48} src={getCardLogo( card.brand )!} /> : card.brand}
        </div>
        <div className="grid-area-b text-start">
            Card Ending in {card.last4Digits}
        </div>
        <div className="grid-area-c">
            {card.expirationMonth}/{card.expirationYear.toString().substring( 2 )}
        </div>
        {used && <div className="position-absolute px-2 py-1 bg-primary rounded-start text-white" style={{ fontSize: "0.70em" ,
            right: "0" , bottom: "7%" }}>
            Used
        </div>}
    </div>
}