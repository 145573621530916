import { Link, useNavigate } from "react-router-dom";
import { max, min, showMoneyShort } from "../Data/Money";
import { PersonWalking } from "react-bootstrap-icons";
import { Button } from "./Button";
import { useAtom } from "jotai";
import { monthlyCheckoutAtom } from "../Data/Atoms";
import { LocalDate } from "js-joda";
import { Facility } from "../Data/ApiTypes";
import { facilityImg } from "../Data/Common";

export interface ResultMonthlyEntryProps {
    facility:   Facility;
    onClick:    () => void;
    className?: string;
    start:      LocalDate;
}

export function ResultMonthlyEntry( props: ResultMonthlyEntryProps ) {
    //hooks
    const nav = useNavigate();
    const [monthlyCheckout, setMonthlyCheckout] = useAtom( monthlyCheckoutAtom );

    //local vars
    const { facility, start, className = "" } = { ...props };
    const { name, listedPlans, walkTime = 5, listImageId } = { ...props.facility };

    function bookNowClick( e: React.MouseEvent<HTMLButtonElement, MouseEvent> ) {
        //just use the first option, they'll have the ability to set it on checkout
        setMonthlyCheckout( {
            facility: facility,
            start: start,
            option: listedPlans[0],
            quantity: 1,
            vehicles: []
        } );
        e.stopPropagation();
        nav( "/checkout/monthly" );
    }

    const prices = listedPlans.map( plan => plan.price );
    const [minPrice, maxPrice] = [min( prices ), max( prices )];
    return <div className={`facility-result rounded-4 p-4 mb-3 shadow border border-2 ${className}`}
                onClick={ () => props.onClick() }>
        <div>
        <img className="facility-result-pic"
             src={facilityImg( listImageId )}
             style={{ aspectRatio: "1 / 1", objectFit: "cover" }} />
        </div>
        <div className="facility-result-info">
            <div className="grid-col-1">
                <div className="letter-spacing-1">
                    {name}
                </div>
                <div className="d-none d-sm-flex align-items-center small">
                    {facility.description}
                </div>
            </div>
            <div className="grid-col-2 position-relative">
                <div className="fs-5 fw-bold">
                    {showMoneyShort( minPrice )}{listedPlans.length > 1 ? "+" : ""}
                </div>
                <div style={{ fontSize: "0.75em", position: "relative", top: "-5%", left: "0.05em" }}>
                    monthly
                </div>
            </div>
            <div className="facility-result-bottom gap-1">
                <div style={{ justifySelf: "center", alignSelf: "center" }} className="">
                    {listedPlans.length > 1 && <>{listedPlans.length} Options</>}
                </div>
                <div className="w-100">
                    <Button className="w-100" onClick={ bookNowClick }>Book </Button>
                </div>
            </div>
        </div>
    </div>;
}