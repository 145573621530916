import React from "react";

export interface PageTitle {
    size?:      number;
    className?: string;
    children:   React.ReactNode;
}

export function PageTitle( { size = 3, children, className = "" }: PageTitle ) {
    return <div className={`fs-${size} ${className} fw-bold`}>
        {children}
    </div>;
}
