import { PaymentMethodCard } from "./PaymentMethodCard";
import { PaymentMethodResp } from "../Data/ApiTypes";
import { PaymentMethodUsBankAccount } from "./PaymentMethodUsBankAccount";
import { Card } from "react-bootstrap";

interface PaymentMethodDisplayProps {
    method: PaymentMethodResp;
    className?: string;
}

interface PaymentMethodUnknownProps {
    method: PaymentMethodResp;
    used?:  boolean;
}

export function PaymentMethodUnknown( props: PaymentMethodUnknownProps ) {
    const { method, used = false } = props;
    return <div className={`shadow credit-card border border-2 border-primary rounded-4 p-3 position-relative text-black`}>
        <div className="grid-area-a w-100">
            {method.type.replace( "_", " " ).toUpperCase()}
        </div>
        {used && <div className="position-absolute px-2 py-1 bg-primary rounded-start text-white" style={{ fontSize: "0.70em" ,
            right: "0" , bottom: "7%" }}>
            Used
        </div>}
    </div>;
}

export function PaymentMethodDisplay({ method, className = "" }: PaymentMethodDisplayProps) {
    switch (method.type) {
        case "card":
            return method.card ? (
                <PaymentMethodCard card={method.card} className={className} used={method.subscriptions.length > 0} />
            ) : null;

        case "us_bank_account":
            return method.usBankAccount ? (
                <PaymentMethodUsBankAccount bankAccount={method.usBankAccount} className={className} used={method.subscriptions.length > 0} />
            ) : null;

        default:
            return <PaymentMethodUnknown method={method} />
    }
}