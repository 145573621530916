import { Bank, Bank2 } from "react-bootstrap-icons";
import { BankResp } from "../Data/ApiTypes";

export interface PaymentMethodBankProps {
    bankAccount: BankResp;
    className?:  string;
    used?:       boolean;
}

export function PaymentMethodUsBankAccount( props: PaymentMethodBankProps ) {
    const { bankAccount,
            className = "",
            used = false,
        } = props;
    return <div className={`${className} shadow credit-card border border-2 border-primary rounded-4 p-3 position-relative text-black`}>
        <div className="grid-area-a gap-1 align-self-center text-center justify-self-center">
            {bankAccount.bank}
        </div>
        <div className="grid-area-b text-start">
            Account Ending in {bankAccount.last4Digits}
        </div>
        <div className="grid-area-c">
            Bank Account
        </div>
        {used && <div className="position-absolute px-3 py-1 bg-primary rounded-start text-white"
                      style={{ fontSize: "0.70em", right: "0" , bottom: "7%" }}>
            In Use
        </div>}
    </div>
}