import { Card, Col, Container, Row, Badge } from "react-bootstrap";
import { cents, showMoney  } from "./Data/Money";
import { useLoaderData, useNavigate     } from "react-router-dom";
import { PageTitle } from "./Controls/PageTitle";
import { facilityImg } from "./Data/Common";
import { SubListEntry, Subscription } from "./Data/ApiTypes";
import { pluralize } from "./Data/English";
import { useAtomValue } from "jotai";
import { authAtom } from "./Data/Atoms";
import { showSubscriptionStatus } from "./Data/Subscription";
import { X } from "react-bootstrap-icons";

export function UserPlansPage() {
    const nav = useNavigate();
    const auth = useAtomValue( authAtom );
    const subs = useLoaderData() as SubListEntry[];

    return <Container fluid>
        <Row>
            <Col>
                <PageTitle>
                    Plans
                </PageTitle>
                {subs.length === 0 && <>
                    You don't have any parking plans
                </>}
                {subs.map( sub => {
                    const facility = sub.facility;
                    const quantity = sub.quantity;
                    const price    = sub.price;
                    const notMine  = sub.parkerId != auth.account!.parkerId;
                    const spots    = sub.quantity;

                    return <Card key={sub.subscriptionId} className="my-2" style={{ cursor: "pointer" }}
                                 onClick={ () => {
                                    if( notMine ) {
                                        nav( `/user/plan/${sub.subscriptionId}/guest` );
                                        return;
                                    }
                                    nav( `/user/plan/${sub.subscriptionId}` );
                                 }}>
                        <Card.Header className="text-start d-flex align-items-center gap-1">
                            {notMine && <Badge className="bg-primary">Guest</Badge>}
                            <Badge>{showSubscriptionStatus( sub.status )}</Badge> {sub.plan.name}
                        </Card.Header>
                        <Card.Body className="purchase-layout gap-2">
                            <div className="grid-area-a">
                                <img className="facility-result-pic"
                                    src={facilityImg( facility.listImageId )}
                                    style={{ aspectRatio: "1 / 1", objectFit: "cover" }} />
                            </div>
                            <div className="grid-area-b">
                                <div className="fw-bold">
                                    {sub.facility.name}
                                </div>
                                <div className="fw-normal">
                                    {spots} {pluralize( quantity, "Spot", "Spots" )}
                                    {notMine ? <></> : <><X /> {showMoney( cents( sub.unitPrice! ) )}</>}
                                </div>
                                {/* {showAddressHtml( facility.address )} */}
                            </div>
                            <div className="grid-area-c position-relative" style={{ justifySelf: "end" }}>
                                <div className="fs-4 fw-bold">
                                    {price && showMoney( cents( price ) )}
                                </div>
                                <div style={{ fontSize: "0.75em", position: "relative", top: "-5%", left: "0.05em" }} className="text-end">
                                    {notMine ? "" : <>monthly</>}
                                </div>
                            </div>
                            <div className="grid-area-d text-end d-flex gap-2">

                            </div>
                        </Card.Body>
                    </Card>;
                } )}
            </Col>
        </Row>
    </Container>;
}