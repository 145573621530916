//third party
import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import { Row, Col, Card } from "react-bootstrap";
import { Navigate, useLoaderData } from "react-router-dom";

//spotsync
import { authAtom, MonthlyCheckout, monthlyCheckoutAtom } from "./Data/Atoms";
import { Button } from "./Controls/Button";
import { MonthlyParkingDetails } from "./Controls/MonthlyParkingDetails";
import { EmailForm } from "./CheckoutPageHourly";
import { CheckoutIndicator } from "./Controls/CheckoutIndicator";
import { VehicleCard } from "./Controls/VehicleCard";
import { Content } from "./Controls/Content";
import { Api } from "./Data/Api";
import { processResult } from "./Data/Result";
import { SubscribeInitResp } from "./Data/ApiTypes";
import { stripePromise } from "./Data/ApiStripe";
import { StripeMonthlyCheckoutForm } from "./StripeMonthlyCheckoutForm";
import { Elements } from "@stripe/react-stripe-js";
import { SubscribeInitReqApi } from "./Data/ApiTransport";
import { parseSubscribeInitResp } from "./Data/ApiParse";
import { StripeElementsOptions } from "@stripe/stripe-js";
import { DateTimeFormatter } from "js-joda";
import { appearanceOptions, stripeFonts } from "./Controls/StripeAppearance";
import { Hyperlink } from "./Controls/Hyperlink";
import { CardHeader } from "./Controls/CardHeader";
import { TermsModal } from "./Controls/TermsModal";

enum CheckoutStep {
    Initial,
    Account,
    Reservation,
    Payment
}

export function CheckoutPageMonthly() {
    const ldCheckout = useLoaderData() as MonthlyCheckout;
    const [ auth,              setAuth        ] = useAtom ( authAtom );
    const [ checkoutUnd,       _              ] = useAtom( monthlyCheckoutAtom );
    const [ checkout,          setCheckout    ] = useState<MonthlyCheckout>( ldCheckout ?? checkoutUnd! );
    const [ step,              setStep        ] = useState( CheckoutStep.Initial );
    const [ terms,             setTerms       ] = useState<string>();
    const [ email,             setEmail       ] = useState<EmailForm>( { emailAddress: auth.isLoggedIn ? auth.account!.emailAddress : "" } );
    const [ subInitResp,       setSubInitResp ] = useState<SubscribeInitResp>();
    const [ paymentVis,        setPaymentVis  ] = useState<"hidden" | "visible">( "hidden" );
    const [ showTerms,         setShowTerms   ] = useState( false );

    useEffect( () => {        
        Api.planTermsGet( checkout.option.planId ).then(
            res => processResult( res,
                val => setTerms( val ),
                err => console.error( `Didn't retrieve terms because: ${err}`) ) ); //don't do anything if they didn't load
    }, [] );

    useEffect( () => {
        if( auth.isLoggedIn ) {
            setEmail( { emailAddress: auth.account!.emailAddress } );
            setStep( CheckoutStep.Reservation );
            return;
        }
        //else
        setEmail( { emailAddress: "" } );
        setStep( CheckoutStep.Account );
    }, [auth.isLoggedIn] );

    useEffect( () => {
        if( step === CheckoutStep.Payment ) {
            const subscribeInitReq: SubscribeInitReqApi = {
                facilityId:   checkout.facility.facilityId,
                planEntryId:  checkout.option.planEntryId,
                start:        checkout.start.format( DateTimeFormatter.ISO_LOCAL_DATE ),
                quantity:     checkout.quantity
            };
            Api.subscriptionInit( subscribeInitReq ).then(
                res => processResult( res,
                    val => {
                        setSubInitResp( parseSubscribeInitResp( val ) );
                        setPaymentVis( "visible" );
                    },
                    err => console.error( err ) ) );
        }
    }, [step] );

    const options: StripeElementsOptions = {
        clientSecret:                subInitResp?.paymentIntentClientSecret,
        customerSessionClientSecret: subInitResp?.customerSessionClientSecret,
        appearance:                  appearanceOptions,
        fonts:                       stripeFonts
    };

    if( !checkout ) {        
        debugger;
        return <Navigate to="/results" />;
    }

    return <Content fluid>
        <Row className="justify-content-center">
            <Col md="6">
                <TermsModal terms={terms ?? "Error!"}
                            show={showTerms}
                            setShow={setShowTerms} />

                <Card className="mb-2">
                    <CardHeader>
                        <CheckoutIndicator step={1} success={step > CheckoutStep.Account} /> Account Information
                    </CardHeader>
                    <Card.Body>
                        {email.emailAddress == "" && <>You must <Hyperlink to={`/login`}>log in</Hyperlink> to purchase Monthly parking.</>}
                        {email.emailAddress !== "" && <>Checking out as {email.emailAddress}</>}
                    </Card.Body>
                </Card>
                <Card className="mb-2">
                    <CardHeader>
                        <CheckoutIndicator step={2} success={step > CheckoutStep.Reservation} /> Monthly Parking Details
                    </CardHeader>
                    {step > CheckoutStep.Account && <Card.Body>
                        <MonthlyParkingDetails
                            disabled={step !== CheckoutStep.Reservation}
                            listedPlans={checkout.facility.listedPlans}
                            start={checkout.start}
                            selOpt={checkout.option}
                            setSelOpt={ (plan) => setCheckout( { ...checkout, option: plan } ) }
                            quantity={checkout.quantity}
                            setQuantity={ qty => setCheckout( { ...checkout, quantity: qty })} />
                        <div className="mt-2">
                            {checkout.vehicles.map( ( v, i ) => <VehicleCard
                                key={i}
                                vehicle={v}
                                deleteVehicle={ () => 0 }
                                vehicles={checkout.vehicles}
                                disabled={step !== CheckoutStep.Reservation } /> )}
                        </div>
                        <div className="my-4 text-center">
                            By clicking "Continue", you agree to our <Hyperlink onClick={ () => setShowTerms( true ) }>Terms and Conditions</Hyperlink>
                        </div>
                        {step === CheckoutStep.Reservation && <div className="mt-2">
                            <Button className="w-100 mt-2" onClick={ () => setStep( CheckoutStep.Payment ) }>Continue to Payment</Button>
                        </div>}
                    </Card.Body>}
                </Card>
                <Card>
                    <CardHeader>
                        <CheckoutIndicator step={3} success={step > CheckoutStep.Payment} /> Payment Details
                    </CardHeader>
                    {step === CheckoutStep.Payment && <Card.Body>
                        <div style={{ visibility: paymentVis }}>
                            {subInitResp && <Elements stripe={stripePromise} options={options}>
                                <StripeMonthlyCheckoutForm subscribeInitResp={subInitResp} />
                            </Elements>}
                        </div>
                    </Card.Body>}
                </Card>
            </Col>
        </Row>
    </Content>;
}