import { ReactNode } from "react";
import { UsAddress } from "./ApiTypes";

export function showFacilityAddress( address: UsAddress ) {
    const { addressLine1: s,
            city:    c,
            state:   st,
            zipCode: z } = { ...address };
    return `${s} ${c}, ${st} ${z}`;
}

export function showFacilityAddressFunc( address: UsAddress, func: ( s: string, c: string, st: string, z: string ) => ReactNode ) {
    const {
        addressLine1: s,
        city:         c,
        state:        st,
        zipCode:      z } = { ...address };
    return func( s, c, st, z );
}

export function showAddressHtml( address: UsAddress ) {
    return showFacilityAddressFunc( address, ( s, c, st, z ) => <div>
        <div>{s}</div>
        <div>{c}, {st} {z}</div>
    </div> );
}