import { Card, CardBody, Col, Container, Row } from "react-bootstrap";
import { PageTitle } from "./Controls/PageTitle";
import { StripeElementsOptions } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { stripePromise } from "./Data/ApiStripe";
import { appearanceOptions, stripeFonts } from "./Controls/StripeAppearance";
import { SettleInitResp } from "./Data/ApiTypes";
import { cents, showMoney } from "./Data/Money";
import { useLoaderData } from "react-router-dom";
import { StripeSettleCheckoutForm } from "./StripeSettleCheckoutForm";
import { showInstantLocalDate } from "./Data/Date";
import { equal } from "dinero.js";
import React from "react";

export function UserSettlePage() {
    const settle = useLoaderData() as SettleInitResp; //just set this
    const show = !equal( settle.price, cents( 0 ) );
    let paymentVis: "hidden" | "visible" = "hidden";
    let msg: string | undefined = "You don't owe anything to this facility at this time.";;
    if( show ) {
        msg        = undefined;
        paymentVis = "visible";
    }
    const orders     = settle.orders;
    const facility   = settle.facility;
    const options: StripeElementsOptions = {
        clientSecret:                settle.paymentIntentClientSecret,
        customerSessionClientSecret: settle.customerSessionClientSecret,
        appearance:                  appearanceOptions,
        fonts:                       stripeFonts
    };

    return <Container fluid>
        <Row>
            <Col>
                <PageTitle>
                    Pay Outstanding Balance
                </PageTitle>                
                <Container className="g-0">
                    <Row>
                        <Col xs={12} className="fs-4">
                            {facility.name}
                        </Col>
                        <Col xs={12}>
                            <p>
                                This page allows you to settle your entire balance right now.
                            </p>
                            <p>
                                To set up your account for autopay, go to Plans, then click on your plan, then click Add Payment Method
                            </p>
                        </Col>
                        <Col xs={12} className="fs-6">
                            <table className="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col">Order</th>
                                  <th scope="col"></th>
                                  <th scope="col"></th>
                                  <th scope="col" className="d-none d-md-table-cell">Price</th>
                                  <th scope="col" className="d-none d-md-table-cell">Paid</th>
                                  <th scope="col">Due</th>
                                </tr>
                              </thead>
                              <tbody>
                                {orders.map( o => <tr>
                                  <td>#{o.orderId}</td>
                                  <td>{showInstantLocalDate(o.createdWhen )}</td>
                                  <td>{o.summary}</td>
                                  <td className="d-none d-md-table-cell">{showMoney( o.balance.totalDebt )}</td>
                                  <td className="d-none d-md-table-cell">{showMoney( o.balance.totalPaid )}</td>
                                  <td>{showMoney( o.balance.balance   )}</td>
                                </tr>)}
                              </tbody>
                            </table>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <div className="fw-bold">
                                Total Due: {showMoney( settle.price )}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>{msg}</Col>
                    </Row>
                </Container>
                <br />
                <div style={{ visibility: paymentVis }}>
                    {show && <Elements stripe={stripePromise} options={options}>
                        <StripeSettleCheckoutForm initResp={settle} />
                    </Elements>}
                </div>
            </Col>
        </Row>
    </Container>;
}