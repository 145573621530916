import { Modal } from "react-bootstrap";
import { HeaderText } from "./HeaderText";
import { Button } from "./Button";

interface TermsModalProps {
    terms: string;
    show: boolean;
    setShow: ( b: boolean ) => void;
}

export function TermsModal( { terms, show, setShow }: TermsModalProps ) {
    return (
        <Modal show={show} centered>
            <Modal.Header>
                <HeaderText>Plan Terms</HeaderText>
            </Modal.Header>
            <Modal.Body>
                <div className="scroll-y-auto" style={{ height: "16em" }}>
                    <pre style={{ whiteSpace: "pre-line" }}>
                        {terms}
                    </pre>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button type="button" className="w-100" onClick={() => setShow( false )}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
}