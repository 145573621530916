import { Form, InputGroup } from "react-bootstrap";
import { Dash, Plus, PlusCircle, PlusCircleDotted, PlusCircleFill, PlusLg, PlusSquareFill } from "react-bootstrap-icons";

export interface AddSubtractProps {
    variant?: string;
    quantity: number;
    set: ( n: number ) => void;
}

function safeAdd( n: number, delta: number ) {
    return Math.max( 1, n + delta );
}

export function AddSubtract( props: AddSubtractProps ) {
    const { variant = "primary", quantity, set } = props;
    const leftCls  = `        user-select-none px-1 py-1 border bg-gradient border-black border-2 text-white fw-bold bg-${variant} bg-gradient rounded-start-5 border-end-0 cursor-pointer`;
    const midCls   = `add-sub user-select-none px-1 py-1 border bg-gradient border-black border-2 text-white fw-bold bg-${variant} bg-gradient border-top      border-start-0 border-end-0 text-center`;
    const rightCls = `        user-select-none px-1 py-1 border bg-gradient border-black border-2 text-white fw-bold bg-${variant} bg-gradient rounded-end-5   border-start-0 cursor-pointer`;

    function setNum( value: string ): void {
        try {
            let val = Math.max( 1, Math.min( 999, parseInt( value ) ) );
            if( val < 1 )
                val = 1;
            if( val > 999 )
                val = 999;
            if( isNaN( val ) )
                val = 1;
            set( val )
        }
        catch {
            //TODO: all the code in here feels dumb
        }
    }

    return <div style={{ fontFamily: "monospace" }} className="d-flex align-items-center">
        <InputGroup className="flex-nowrap">
            <InputGroup.Text
                className={leftCls}
                onClick={ () => set( safeAdd( quantity, -1 ) ) }>
                &nbsp;&mdash;&nbsp;
            </InputGroup.Text>
            <Form.Control
                style={{ width: "3em" }}
                className={midCls}
                type="number"
                value={quantity}
                onChange={ e => setNum( e.target.value )} />
            <InputGroup.Text
                className={rightCls}
                onClick={ () => set( safeAdd( quantity, +1 ) ) }>
                &nbsp;+&nbsp;
            </InputGroup.Text>
        </InputGroup>
        {/* <span className="user-select-none px-2 border border-black border-2 text-white bg-primary rounded-start-4 border-end-0 cursor-pointer"
              onClick={ () => set( safeAdd( quantity, -1 ) ) }>
            -
        </span>
        <span className="user-select-none px-2 border border-black border-top border-end-0 border-2 text-white bg-primary">
            {quantity}
        </span>
        <span className="user-select-none px-2 border border-black border-2 text-white bg-primary rounded-end-4 cursor-pointer"
            onClick={ () => set( safeAdd( quantity, 1 ) ) }>
            +
        </span> */}
    </div>;
}