import { Container, Row, Col, Badge, Card } from "react-bootstrap";
import { PageTitle } from "./Controls/PageTitle";
import { useParamIds } from "./Data/Common";
import { MapAddress } from "./Controls/MapAddress";
import { Link, useLoaderData } from "react-router-dom";
import { SubscriptionGuest, Vehicle } from "./Data/ApiTypes";
import { useState } from "react";
import { Api } from "./Data/Api";
import { AddVehicleModal } from "./Controls/AddVehicleModal";
import { getInstructionsGuest, showSubscriptionStatus } from "./Data/Subscription";
import { ReservationVehicleCard } from "./UserPlanDetailsPage";
import { pluralize } from "./Data/English";
import { SubscriptionStatus } from "./Data/ApiTransport";
import { Button } from "./Controls/Button";
import { CardChecklist, Clock } from "react-bootstrap-icons";
import { ChooseVehicleModal } from "./Controls/ChooseVehicleModal";

export interface ReservationVehicleCardProps {
    vehicle: Vehicle;
    onRemove: ( v: Vehicle ) => void;
}

interface EntryProps {
    icon:   React.ReactNode;
    header: React.ReactNode;
    body:   React.ReactNode;
    className? : string;
}

function Entry( { icon, header, body, className = "" }: EntryProps ) {
    return <div className={`${className} mb-3 d-flex d-flex-align-center`}>
        <div>
            {icon}
        </div>
        <div>
            <div className="fw-bold">
                {header}
            </div>
            <div>
                {body}
            </div>
        </div>
    </div>;
}

export function UserPlanDetailsGuestPage() {
    const { planId }                    = useParamIds();
    const [showAdd, setShowAdd]         = useState( false );
    const [showChoose, setShowChoose]   = useState( false );
    const [sub, loadedVehicles]         = useLoaderData() as [SubscriptionGuest, Vehicle[]];
    const [subVehicles, setSubVehicles] = useState( sub.vehicles );
    const [myVehicles, setMyVehicles  ] = useState( loadedVehicles );
    const facility                      = sub.facility;
    const quantity                      = sub.quantity;
    const plan                          = sub.plan;
    const periods                       = sub.accessPeriods;
    const noAccess                      = sub.accessPeriods.length === 0;
    const earliest = periods.length !== 0 ? periods.toSorted( (a, b) => a.start.toEpochDay() - b.start.toEpochDay() )[0] : undefined;
    const latest   = periods.length !== 0 ? periods.toSorted( (a, b) => a.end.toEpochDay()   - b.end.toEpochDay()   )[0] : undefined;

    async function vehicleAdd( data: Vehicle ) {
        const addRes = await Api.vehicleAdd( data );
        if( !addRes.isOk ) {
            alert( addRes.error );
            return;
        }
        await vehicleLink( addRes.value );
    }

    async function vehicleLink( vehicle: Vehicle ) {
        const addToSub = await Api.subscriptionVehicleAdd( sub.subscriptionId, { vehicleId: vehicle.vehicleId } );
        if( !addToSub.isOk ) {
            alert( addToSub.error );
            return;
        }
        setSubVehicles( [ ...subVehicles, vehicle ] );
    }

    async function vehicleRemove( vehicle: Vehicle ) {
        const deleteRes = await Api.subscriptionVehicleDelete(
            sub.subscriptionId, vehicle.vehicleId, { vehicleId: vehicle.vehicleId }
        );
        if( !deleteRes.isOk ) {
            alert( deleteRes.error );
            return;
        }
        setSubVehicles( subVehicles.filter( v => {
            return vehicle.vehicleId !== v.vehicleId;
        } ) );
    }

    return <Container fluid>
         <AddVehicleModal show={showAdd}
                          setShow={setShowAdd}
                          onSubmit={ vehicleAdd }
                          />

         <ChooseVehicleModal show={showChoose}
                             setShow={setShowChoose}
                             onSubmit={vehicleLink}
                             vehicles={myVehicles}
                             exclude={subVehicles.map( sv => sv.vehicleId )}
                             />

        <Row>
            <Col>
                <Container fluid className="g-0">
                    <Row>
                        <Col xs="12">
                            <PageTitle className="d-flex align-items-center gap-1">
                                 {facility.name}
                            </PageTitle>
                            <Card className="collapse-window">
                                <Card.Header className="collapse-window d-flex align-items-center gap-2">
                                    <Badge>{showSubscriptionStatus( sub.status )}</Badge> {plan.name}
                                </Card.Header>
                                <Card.Body className="mx-3">
                                    <Entry icon={<Clock />}         header="Access Hours" body={plan.accessHours} />
                                    <div className="mt-3 d-flex align-items-center justify-items-center">
                                        <Entry icon={<CardChecklist />}
                                               header="Entry Instructions"
                                               body={facility.entryInstructions}
                                               className="me-auto" />
                                        <div>
                                            <Button>
                                                <MapAddress address={facility.address}>
                                                    Get Directions
                                                </MapAddress>
                                            </Button>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                            <div className="d-flex align-items-center gap-1">

                            </div>
                            <div>
                                {sub.status !== SubscriptionStatus.Active && getInstructionsGuest( sub.status )}
                            </div>
                            <div className="mt-2 text-primary">
                                You are a guest on this plan.
                            </div>
                            <div className="mt-2">
                                {sub.parker.name} has allocated <strong>{sub.quantity}</strong>
                                &nbsp;{pluralize( sub.quantity, "spot", "spots" )} to you
                            </div>
                        </Col>
                    </Row>
                </Container>
                <Card className="mt-3">
                    <Card.Header style={{ display: "grid", gridTemplateColumns: "max-content 1fr max-content max-content"}}>
                        <div>
                            My Vehicles
                        </div>
                        <div>

                        </div>
                        <div>
                            <Link to="" onClick={ () => setShowAdd( true )}>Add New</Link>&nbsp; |
                            {myVehicles.length !== 0 && <>&nbsp;<Link to="" onClick={ () => setShowChoose( true )}>Add Existing</Link></>}
                        </div>
                    </Card.Header>
                    <Card.Body>
                        {subVehicles.length === 0 && <div className="text-secondary">
                            You can add or remove vehicles to this plan at any time.  Each vehicle listed here uses a spot
                        </div>}
                        {subVehicles.map( v => <ReservationVehicleCard vehicle={v} onRemove={vehicleRemove} />)}
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    </Container>;
}
