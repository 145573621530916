import { Link, useLoaderData } from "react-router-dom";
import { PageTitle                } from "./Controls/PageTitle";
import { Col, Container, Row } from "react-bootstrap";
import { useState } from "react";
import { PaymentMethodResp } from "./Data/ApiTypes";
import { PaymentMethodDisplay } from "./Controls/PaymentMethod";

export function UserBillingPage() {
    const methods = useLoaderData() as PaymentMethodResp[];
    const [paymentMethods, setPaymentMethods] = useState<PaymentMethodResp[]>( methods );

    return <Container fluid>
        <Row>
            <Col md="12">
                <div className="d-flex align-items-center gap-2">
                    <PageTitle className="d-flex align-items-center gap-2">
                        Payment Methods
                        <Link to="https://docs.stripe.com/security" target="_blank" rel="noopener noreferrer">
                            <img src="/img/powered-by-stripe.svg" style={{ height: "1.1em" }} />
                        </Link>
                    </PageTitle>
                </div>
                <div className="text-secondary">
                    Your information is securely stored by <b>Stripe</b>
                </div>
                <div className="my-2">
                    {/* <Hyperlink to="/user/billing/add">
                        Add Payment Method
                    </Hyperlink> */}
                </div>
                <Container className="g-0">
                    <Row className="gap-3 mt-3">
                        {paymentMethods.length > 0 && paymentMethods.map( p => {
                            return <Col key={p.paymentMethodId} md="auto" className="d-flex justify-items-center justify-content-center zoom-small">
                                <Link to={`/user/billing/${p.paymentMethodId}`}>
                                    <PaymentMethodDisplay method={p} />
                                </Link>
                            </Col>;
                        } )}
                    </Row>
                </Container>
            </Col>
        </Row>
    </Container>;
}