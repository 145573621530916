import { LocalDate } from "js-joda";
import { SubscriptionStatus } from "./ApiTransport";
import { Subscription } from "./ApiTypes";

export const showSubscriptionStatus = ( status: SubscriptionStatus ): string => {
    switch ( status ) {
        case SubscriptionStatus.Applied:            return 'Pending';
        case SubscriptionStatus.Rejected:           return 'Inactive';
        case SubscriptionStatus.WaitingForContract: return 'Pending';
        case SubscriptionStatus.Active:             return 'Active';
        case SubscriptionStatus.Cancelled:          return 'Inactive';
        default:
            return 'Unknown Status';
    }
};

export const getInstructionsShort = ( status: SubscriptionStatus ): string => {
    if( status == SubscriptionStatus.Applied ) {
        return "Under Review";
    }
    if( status == SubscriptionStatus.Rejected ) {
        return "Application Rejected";
    }
    if( status == SubscriptionStatus.WaitingForContract ) {
        return "Waiting for Signature";
    }
    if( status == SubscriptionStatus.Active ) {
        return "Active";
    }
    if( status == SubscriptionStatus.Cancelled ) {
        return "Plan Cancelled";
    }
    return "Unknown status";
}

export const getInstructions = ( status: SubscriptionStatus ): string => {
    if( status == SubscriptionStatus.Applied ) {
        return "Your application is being reviewed, you should receive e-mail confirmation in 24-48 hours";
    }
    if( status == SubscriptionStatus.Rejected ) {
        return "Your application was rejected.  This is most likely due to lack of capacity.  Please contact the facility for more information";
    }
    if( status == SubscriptionStatus.WaitingForContract ) {
        return "Your application was approved, someone should be in touch with a contract shortly.";
    }
    if( status == SubscriptionStatus.Active ) {
        return "Your plan is active and will auto-renew if payment method is present.";
    }
    if( status == SubscriptionStatus.Cancelled ) {
        return "Your plan is cancelled and will not renew.";
    }
    return "Unknown status";
}

export const getInstructionsGuest = ( status: SubscriptionStatus ): string => {
    if( status == SubscriptionStatus.Applied ) {
        return "The plan holder's application is under review";
    }
    if( status == SubscriptionStatus.Rejected ) {
        return "The plan holder's application was rejected";
    }
    if( status == SubscriptionStatus.WaitingForContract ) {
        return "A contract has been sent to the plan holder, if they sign the plan will become active ";
    }
    if( status == SubscriptionStatus.Active ) {
        return "The plan is active and will auto-renew.";
    }
    if( status == SubscriptionStatus.Cancelled ) {
        return "The plan was cancelled and will not renew.";
    }
    return "Unknown status";
}

export function isSubscriptionActive( status: SubscriptionStatus ) {
    return status !== SubscriptionStatus.Active;
}

export function currentSpaces( sub: Subscription ) {
    const currentQuantity = sub.accessPeriods.filter( ap => ap.start <= LocalDate.now() && LocalDate.now() < ap.end ).map( ap => ap.quantity ).reduce( (a, b) => a + b, 0 );
    return currentQuantity;
}