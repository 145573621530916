import { Badge, Card, Col, Container, Row } from "react-bootstrap";
import { PageTitle } from "./Controls/PageTitle";
import { BillingAddress } from "./Data/FakeData";
import { Link, useLoaderData, useNavigate } from "react-router-dom";
import { useState } from "react";
import { DeletePaymentMethodModal } from "./UserBillingPageDeletePaymentMethodModal";
import { ExpDateModal } from "./UserBillingDetailsPageExpDateModal";
import { BillingAddressModal } from "./UserBillingDetailsPageBillingAddressModal";
import { PaymentMethodResp } from "./Data/ApiTypes";
import { PaymentMethodDisplay } from "./Controls/PaymentMethod";
import { X } from "react-bootstrap-icons";
import { facilityImg } from "./Data/Common";
import { pluralize } from "./Data/English";
import { showSubscriptionStatus } from "./Data/Subscription";
import { showMoney, cents } from "./Data/Money";
import { Api } from "./Data/Api";
import { processResult } from "./Data/Result";

export function UserBillingDetailsPage() {
    const nav = useNavigate();
    const pm = useLoaderData() as PaymentMethodResp; //payment method
    const [ cardInfo,         setCardInfo         ] = useState<PaymentMethodResp>( pm );
    const [ showDelete,       setShowDelete       ] = useState( false );
    const [ showExpDateModal, setShowExpDateModal ] = useState( false );
    const [ showAddressModal, setShowAddressModal ] = useState( false );

    const handleExpirationSave = ( { expMonthYear }: { expMonthYear: string } ) => {
        let arr = expMonthYear.split( "/" );
        // setCardInfo( prevInfo => ( {
        //     ...prevInfo,
        //     expirationMonth: arr[0],
        //     expirationYear:  arr[1]
        // } ) );
    };

    const handleAddressSave = (data: BillingAddress) => {
        // setCardInfo( prevInfo => ( {
        //     ...prevInfo,
        //     ...data
        // } ) );
    };

    //this payment method is on file for these subscriptions
    return <Container fluid>

        <ExpDateModal
            show={showExpDateModal}
            setShow={setShowExpDateModal}
            onSave={handleExpirationSave} />

        <BillingAddressModal
            show={showAddressModal}
            setShow={setShowAddressModal}
            onSave={handleAddressSave} />

        <DeletePaymentMethodModal method={pm}
                                  show={showDelete}
                                  setShow={setShowDelete}
                                  used={pm.subscriptions.length > 0}
                                  onDelete={ () => {
                                        Api.stripePaymentMethodDelete( pm.paymentMethodId )
                                            .then( x => processResult( x,
                                                val => {
                                                    nav( "/user/billing" );
                                                },
                                                err => alert( err )
                                            ) );
                                  }}
         />
        <Row>
            <Col md="12">
                <PageTitle>
                    Saved Payment Method
                </PageTitle>
                <Container>
                    {cardInfo && <Row className="gap-2">
                        <Col className="g-0" xs="auto">
                            <PaymentMethodDisplay method={cardInfo} />
                        </Col>
                        <Col className="g-0 lh-lg" xs="auto">
                            {/* <div>
                                <Link to="" onClick={ () => setShowExpDateModal( true )}>
                                    Change Expiration Date
                                </Link>
                            </div>
                            <div>
                                <Link to="" onClick={ () => setShowAddressModal( true )}>
                                    Change Billing Address
                                </Link>
                            </div> */}
                            <div>
                                <Link to="" onClick={ () => setShowDelete( true ) }>
                                    Delete
                                </Link>
                            </div>
                        </Col>
                    </Row>}
                    {pm.subscriptions.length !== 0 && <Row>
                        <Col className="g-0 mt-4">
                            <h4>Subscriptions</h4>
                            <div className="mt-2">
                                {pm.subscriptions.map( sub => {
                                    const facility = sub.facility;
                                    const quantity = sub.quantity;
                                    const price    = sub.price;
                                    const spots    = sub.quantity;

                                    return <Card key={sub.subscriptionId} className="my-2">
                                        <Card.Header className="text-start d-flex align-items-center gap-1">
                                            <Badge>{showSubscriptionStatus( sub.status )}</Badge> {sub.plan.name}
                                        </Card.Header>
                                        <Card.Body className="purchase-layout gap-2">
                                            <div className="grid-area-a">
                                                <img className="facility-result-pic"
                                                    src={facilityImg( facility.listImageId )}
                                                    style={{ aspectRatio: "1 / 1", objectFit: "cover" }} />
                                            </div>
                                            <div className="grid-area-b">
                                                <div className="fw-bold">
                                                    {sub.facility.name}
                                                </div>
                                                <div className="fw-normal">
                                                    {spots} {pluralize( quantity, "Spot", "Spots" )}
                                                    <X /> {showMoney( cents( sub.unitPrice! ) )}
                                                </div>
                                            </div>
                                            <div className="grid-area-c position-relative" style={{ justifySelf: "end" }}>
                                                <div className="fs-4 fw-bold">
                                                    {price && showMoney( cents( price ) )}
                                                </div>
                                                <div style={{ fontSize: "0.75em", position: "relative", top: "-5%", left: "0.05em" }} className="text-end">
                                                    monthly
                                                </div>
                                            </div>
                                        </Card.Body>
                                    </Card>;
                                } )}
                            </div>
                        </Col>
                    </Row>}
                </Container>
            </Col>
            <Col>

            </Col>
        </Row>
    </Container>;
}