import { useLoaderData } from "react-router-dom";
import { showAddressHtml, showFacilityAddress } from "./Data/Location";
import { Badge, Card, Col, Container, Row } from "react-bootstrap";
import { PageTitle } from "./Controls/PageTitle";
import { Facility, FacilityInfo } from "./Data/ApiTypes";
import { facilityImg, useParamIds } from "./Data/Common";
import { Compass, TelephoneOutbound, MailboxFlag } from "react-bootstrap-icons";
import { CardButton } from "./CardButton";

export function FacilityDetailsPage() {
    const facility = useLoaderData() as Facility;
    const { facilityId } = useParamIds();

    return <Container>
        <Row>
            <Col xs="12" className="gap-2"
                style={{
                    display: "grid",
                    gridTemplateColumns: "min-content 1fr",
                    gridTemplateAreas: `'a a' 'b c' 'd e'`
                }}>
                <PageTitle className="grid-area-a">
                    {facility.name}
                </PageTitle>
                <div className="grid-area-b d-flex flex-column gap-2">
                    <img className="rounded-4"
                         src={facilityImg( facility.listImageId )}
                         style={{ width: "150px", aspectRatio: "1 / 1", objectFit: "cover" }} />
                    <div style={{ width: "150px", aspectRatio: "1 / 1", objectFit: "cover" }}
                         className="card card-body rounded-4 h-100 d-flex flex-column align-items-center justify-content-center">
                        <div className="fs-3">
                            {facility.clearanceInches}"
                        </div>
                        <div className="fs-6">
                            Max Height
                        </div>
                    </div>
                </div>
                <div className="grid-area-c gap-2 justify-items-center" style={{ placeContent: "center stretch", display: "grid", gridTemplateRows: "1fr 1fr 1fr" }}>
                    <Card>
                        <Card.Body>
                            <div className="fw-semibold text-muted">
                                Address
                            </div>
                            <div>
                                {showAddressHtml( facility.address )}
                            </div>
                        </Card.Body>
                    </Card>
                    <Card>
                        <Card.Body>
                            <div className="fw-semibold text-muted">
                                Description
                            </div>
                            <div>
                                {facility.description}
                            </div>
                        </Card.Body>
                    </Card>
                    <Card>
                        <Card.Body>
                            <div className="fw-semibold text-muted">
                                Entry
                            </div>
                            <div>
                                {facility.entryInstructions}
                            </div>
                        </Card.Body>
                    </Card>
                </div>
            </Col>
            <Col>
                <div className="mb-3 gap-2 w-100" style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr" }}>
                    <CardButton
                        onClick={() => window.open( `http://maps.apple.com/?q=${encodeURIComponent( showFacilityAddress( facility.address ) )}` )}
                        title={<Compass className="text-primary" />}
                        subtitle="Navigate" />

                    <CardButton
                        onClick={() => window.open( `tel:${facility.phoneNumber}` )}
                        title={<TelephoneOutbound className="text-primary" />}
                        subtitle={facility.phoneNumber} />

                    <CardButton
                        onClick={() => window.open( `mailto:${facility.emailAddress}` )}
                        title={<MailboxFlag className="text-primary" />}
                        subtitle={facility.emailAddress} />

                </div>
            </Col>
        </Row>
    </Container>;
}