import { Modal } from "react-bootstrap";
import { Alphabet, At, Bootstrap, BootstrapFill, ExclamationCircle, Icon0Circle, Key, KeyFill, NodeMinus, PersonAdd, PersonDashFill, PersonFill, PersonFillAdd, PersonFillUp, PersonFillX, PersonX, PlusSlashMinus, QuestionCircleFill, Send, ShareFill, SignNoParkingFill, Trash2, Trash2Fill, XCircle } from "react-bootstrap-icons";
import { useForm } from "react-hook-form";
import { HeaderText } from "./Controls/HeaderText";
import { Input } from "./Controls/Input";
import { Button } from "./Controls/Button";

export interface InviteParkerModalProps {
    show:    boolean;
    setShow: ( show: boolean ) => void;
    submit:  ( data: InviteParkerForm  ) => void;
}

export interface InviteParkerForm {
    name:         string;
    phoneNumber:  string;
    emailAddress: string;
}

export function InviteParkerModal( { show, setShow, submit }: InviteParkerModalProps ) {
    const { register, handleSubmit, formState: { errors }, reset }
        = useForm<InviteParkerForm>( { defaultValues: { name: "", emailAddress: "", phoneNumber: "" }});

    function submitImpl( data: InviteParkerForm  ) {
        setShow( false );
        submit( data );
        reset();
    }

    return <Modal centered show={show} onEscapeKeyDown={ () => setShow( false ) }>
        <form onSubmit={ handleSubmit( submitImpl ) }>
        <Modal.Header>
            <HeaderText>Invite Parker</HeaderText>
        </Modal.Header>
        <Modal.Body>
                <div className="text-secondary gap-4 px-2 mb-4" style={{ display: "grid", gridTemplateColumns: "auto 1fr", alignItems: "center" }}>
                    <Send size="32" className="text-primary" /> <span>Invite someone to your plan to let them park!</span>
                </div>
                <div className="text-secondary gap-4 px-2 mb-3" style={{ display: "grid", gridTemplateColumns: "auto 1fr", alignItems: "center" }}>
                    <Key size="32" className="text-primary" /> <span>You can add or remove spots or access from them at any time</span>
                </div>
                {/* <div className="text-secondary gap-4 px-2 mb-0" style={{ display: "grid", gridTemplateColumns: "auto 1fr", alignItems: "center" }}>
                    <Trash2 size="32" className="text-primary" /> <span>Or put them entirely in the trash!</span>
                </div> */}
                <Input
                        autoFocus
                        type="text"
                        label="Name"
                        icon={<Alphabet className="fs-5" />}
                        placeholder="Enter their Name"
                        {...register( "name", { required: "Name is required" } )}
                        error={errors.name}
                    />
                    <Input
                        type="email"
                        label="E-mail Address"
                        icon={<At className="fs-5" />}
                        placeholder="Enter their Email address"
                        {...register( "emailAddress", { required: "Email Address is required" } )}
                        error={errors.emailAddress}
                    />
                    {/* <Input
                        type="tel"
                        label="Phone Number"
                        icon={<Telephone className="fs-5" />}
                        placeholder="###-###-####"
                        error={errors.phoneNumber}
                        {...register("phoneNumber", {
                            required: "Phone number is required",
                            pattern: {
                                value: /^\d{3}-\d{3}-\d{4}$/,
                                message: "Phone number must be in format ###-###-####"
                            }
                        })}
                    /> */}
        </Modal.Body>
        <Modal.Footer className="d-flex">
            <Button className="flex-grow-1" onClick={ () => setShow( false )}>
                Cancel
            </Button>
            <Button className="flex-grow-1" type="submit">
                Submit
            </Button>
        </Modal.Footer>
        </form>
    </Modal>
}