import { ReactNode } from "react";
import { Card } from "react-bootstrap";
import { Button } from "./Controls/Button";
import { SquareButton } from "./Controls/SquareButton";

export interface CardButtonProps {
    className?:         string;
    titleClassName?:    string;
    subtitleClassName?: string;
    title:              ReactNode;
    subtitle:           ReactNode;
    onClick?:           () => void;
}

export function CardButton( props: CardButtonProps ) {
    const { className         = "text-center",
            titleClassName    = "fs-3",
            subtitleClassName = "text-muted",
            title,
            subtitle,
            onClick = () => 0 } = { ...props };
    return <SquareButton className={className} onClick={onClick}>
        <Card.Body>
            <div className={titleClassName}>
                {title}
            </div>
            <small className={subtitleClassName}>
                {subtitle}
            </small>
        </Card.Body>
    </SquareButton>
}
